import * as React from "react";
import Nav from "../components/nav/Nav";
import Hero from "../components/hero/Hero";
import SecondSection from "../components/secondSection/SecondSection";
import { graphql } from "gatsby";
import ThirdSection from "../components/thirdSection/ThirdSection";
import FourthSection from "../components/fourthSection/FourthSection";
import FifthSection from "../components/fifthSection/FifthSection";
import SixthSection from "../components/sixthSection/SixthSection";
import SeventhSection from "../components/seventhSection/SeventhSection";
import Footer from "../components/footer/Footer";
import EighthSection from "../components/eighthSection/EighthSection";
import NinthSection from "../components/ninthSection/NinthSection";
import { HelmetDatoCms } from "gatsby-source-datocms";
import MobileSeventhSection from "../components/MobileSevenSection/MobileSevenSection";

const IndexPage = ({ data: { metaTags, contact, hero, secondSection, thirdSection, fourthSection, fifthSection, sixthSection, seventhSection, eighthSection, ninthSection, footer } }) => {
  return (
    <section className="bg-black">
      {/* <HelmetDatoCms>
        <title>{metaTags.globalseo.siteName}</title>
        <link rel="alternate" href="http://www.mysite.com/it/" hreflang="it" />
        <link rel="alternate" href="http://www.mysite.com/fr/" hreflang="fr" />
      </HelmetDatoCms> */}
      <HelmetDatoCms>
        <title>Escape Hotel &mdash; A Place to Relax</title>
      </HelmetDatoCms>
      <Nav contact={contact} />
      <Hero hero={hero} />
      <SecondSection secondSection={secondSection}></SecondSection>
      <ThirdSection thirdSection={thirdSection}></ThirdSection>
      <FourthSection fourthSection={fourthSection}></FourthSection>
      <FifthSection fifthSection={fifthSection}></FifthSection>
      <SixthSection sixthSection={sixthSection}></SixthSection>
      <div className="hidden lg:flex">
        <SeventhSection seventhSection={seventhSection}></SeventhSection>
      </div>
      <div className="flex lg:hidden">
        <MobileSeventhSection seventhSection={seventhSection} />
      </div>
      <EighthSection eighthSection={eighthSection}></EighthSection>
      <NinthSection ninthSection={ninthSection} contact={contact}></NinthSection>
      <Footer footer={footer} contact={contact}></Footer>
    </section>
  );
};

export default IndexPage;

export const query = graphql`
  {
    metaTags: datoCmsSite {
      globalSeo {
        siteName
        titleSuffix
        twitterAccount
        facebookPageUrl
        fallbackSeo {
          title
          description
          image {
            url
          }
          twitterCard
        }
      }
    }

    contact: datoCmsContact {
      phone
      facebook
      instagram
      location
      email
    }
    hero: datoCmsHero {
      herotext
      heroimg {
        url
      }
    }
    secondSection: datoCmsSecondSection {
      sectionParagraph
    }
    thirdSection: datoCmsThirdSection {
      subscript
      sectionHeading
      sectionBody
      upperPhoto {
        alt
        url
      }
      lowerPhoto {
        url
        alt
      }
    }
    fourthSection: datoCmsFourthSection {
      subcript
      sectionHeading
      sectionBody
      photo1 {
        url
        alt
      }
      photo2 {
        url
        alt
      }
      photo3 {
        url
        alt
      }
      photo4 {
        url
        alt
      }
    }
    fifthSection: datoCmsFifthSection {
      subcript
      sectionHeading
      sectionBody
      photo {
        url
        alt
      }
    }
    sixthSection: datoCmsSixthSection {
      subcript
      sectionHeading
      sectionBody
      photo1Title
      photo1 {
        url
        alt
      }
      photo2Title
      photo2 {
        url
        alt
      }
      photo3Title
      photo3 {
        url
        alt
      }
    }
    seventhSection: datoCmsSeventhSection {
      subscript
      sectionHeading
      sectionBody
      photo1 {
        url
        alt
      }
      photo1Title
      photo1Detail
      photo2 {
        url
        alt
      }
      photo2Title
      photo2Detail
      photo3 {
        url
        alt
      }
      photo3Title
      photo3Detail
      photo4 {
        url
        alt
      }
      photo4Title
      photo4Detail
      photo5 {
        url
        alt
      }
      photo5Title
      photo5Detail
      photo6 {
        url
        alt
      }
      photo6Title
      photo6Detail
    }
    eighthSection: datoCmsEighthSection {
      photo1 {
        url
        alt
      }
      photo2 {
        url
        alt
      }
      photo3 {
        url
        alt
      }
      photo4 {
        url
        alt
      }
      photo5 {
        url
        alt
      }
      photo6 {
        url
        alt
      }
      photo7 {
        url
        alt
      }
      photo8 {
        url
        alt
      }
      photo9 {
        url
        alt
      }
    }
    ninthSection: datoCmsNinthSection {
      subscript
      sectionHeading
      map {
        url
        alt
      }
    }
    footer: datoCmsFooter {
      logo {
        url
      }
    }
  }
`;
