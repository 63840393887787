import React from "react";

export default function Nav({ contact }) {
  return (
    <div className="absolute z-30 top-0 pt-4 w-screen flex items-center justify-center">
      <nav className="container px-4 md:px-10 mx-auto h-20 flex items-center justify-center">
        <div className="w-1/3 text-white text-sm cursor-pointer items-start justify-start">
          <span onClick={() => window.open(`tel:+${contact.phone}`)} className="cursor-pointer flex">
            <svg xmlns="http://www.w3.org/2000/svg" className="mr-2" viewBox="0 0 24 24" width="24" height="24">
              <path fill="none" d="M0 0h24v24H0z" />
              <path
                d="M9.366 10.682a10.556 10.556 0 0 0 3.952 3.952l.884-1.238a1 1 0 0 1 1.294-.296 11.422 11.422 0 0 0 4.583 1.364 1 1 0 0 1 .921.997v4.462a1 1 0 0 1-.898.995c-.53.055-1.064.082-1.602.082C9.94 21 3 14.06 3 5.5c0-.538.027-1.072.082-1.602A1 1 0 0 1 4.077 3h4.462a1 1 0 0 1 .997.921A11.422 11.422 0 0 0 10.9 8.504a1 1 0 0 1-.296 1.294l-1.238.884zm-2.522-.657l1.9-1.357A13.41 13.41 0 0 1 7.647 5H5.01c-.006.166-.009.333-.009.5C5 12.956 11.044 19 18.5 19c.167 0 .334-.003.5-.01v-2.637a13.41 13.41 0 0 1-3.668-1.097l-1.357 1.9a12.442 12.442 0 0 1-1.588-.75l-.058-.033a12.556 12.556 0 0 1-4.702-4.702l-.033-.058a12.442 12.442 0 0 1-.75-1.588z"
                fill="rgba(254,247,237,1)"
              />
            </svg>
            <span className="hidden md:flex">{contact.phone}</span>
          </span>
        </div>
        <div className="w-1/3 mt-10 pt-10 flex flex-col items-center justify-center">
          <svg className="w-14 h-14" xmlns="http://www.w3.org/2000/svg" width="575.154" height="452.934" viewBox="0 0 575.154 452.934">
            <path
              id="Path_44"
              data-name="Path 44"
              d="M739.552,645.172s-4.978-7.622,37.63-5.963c59.15,2.3,178.153,13.115,236.622,3.288,45.435-7.636,52.943-39.678,57.972-50.961.722-1.621,1.7-8.107,1.234-10.9-2.2-13.631-25.41-33.324-62.465-45.275-63.065-20.343-163.883-27.858-200.7-30.7-9.166-.708-6.643-2.3-6.643-2.3s257.589-17.834,359.654,14.726c39.194,12.5,63.484,31.637,68.79,52.024,8.132,31.213-20.185,63.492-20.185,63.492s37.855-9.433,56.334-3.451,17.579,27.379,17.579,27.379,16.038.055,23.292,8.143,5.723,24.209,5.723,24.209-20.574-13.514-43.938-10.483-49.517,22.608-49.517,22.608,51.412,48.028-13.824,110.379c-47.557,45.454-158.694,104.79-215.055,130.431-20.3,9.233-32.065,8.593-32.065,8.593s-6.279-1.165,4.559-12.5c41.8-43.732,159.27-165.627,118.475-208.155-31.309-32.64-151.525-10.659-237.053,2.365-28.409,4.325-52.142,11.163-68.683,11.459-13.225.237-18.6-2.453-18.6-2.453s-12.965-2.928,27.362-16.663c26.334-8.968,73.377-18.522,104.033-27.239,15.443-4.391,21.709-6.7,21.709-6.7s26.431-7.992-18.014-17.408c-21.155-4.482-65.942-5.234-98.248-10.939-20.208-3.568-39.3-4.9-49.432-8.826A9.851,9.851,0,0,1,739.552,645.172Z"
              transform="translate(-739.373 -497.489)"
              fill="#fef7ed"
            />
          </svg>
          <p className="my md:text-5xl text-2xl mt-2 text-orange-50 uppercase">Escape</p>
          <p className="nord text-base tracking-widest text-orange-50">HOTEL</p>
        </div>
        <div className="w-1/3 flex items-end justify-end">
          <a className="cursor-pointer" href={contact.facebook}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="28" height="28">
              <path fill="none" d="M0 0h24v24H0z" />
              <path
                d="M15.402 21v-6.966h2.333l.349-2.708h-2.682V9.598c0-.784.218-1.319 1.342-1.319h1.434V5.857a19.19 19.19 0 0 0-2.09-.107c-2.067 0-3.482 1.262-3.482 3.58v1.996h-2.338v2.708h2.338V21H4a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h16a1 1 0 0 1 1 1v16a1 1 0 0 1-1 1h-4.598z"
                fill="rgba(254,247,237,1)"
              />
            </svg>
          </a>
          <a className="md:ml-5 ml-2 cursor-ponter" href={contact.instagram}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="28" height="28 ">
              <path fill="none" d="M0 0h24v24H0z" />
              <path
                d="M12 2c2.717 0 3.056.01 4.122.06 1.065.05 1.79.217 2.428.465.66.254 1.216.598 1.772 1.153a4.908 4.908 0 0 1 1.153 1.772c.247.637.415 1.363.465 2.428.047 1.066.06 1.405.06 4.122 0 2.717-.01 3.056-.06 4.122-.05 1.065-.218 1.79-.465 2.428a4.883 4.883 0 0 1-1.153 1.772 4.915 4.915 0 0 1-1.772 1.153c-.637.247-1.363.415-2.428.465-1.066.047-1.405.06-4.122.06-2.717 0-3.056-.01-4.122-.06-1.065-.05-1.79-.218-2.428-.465a4.89 4.89 0 0 1-1.772-1.153 4.904 4.904 0 0 1-1.153-1.772c-.248-.637-.415-1.363-.465-2.428C2.013 15.056 2 14.717 2 12c0-2.717.01-3.056.06-4.122.05-1.066.217-1.79.465-2.428a4.88 4.88 0 0 1 1.153-1.772A4.897 4.897 0 0 1 5.45 2.525c.638-.248 1.362-.415 2.428-.465C8.944 2.013 9.283 2 12 2zm0 5a5 5 0 1 0 0 10 5 5 0 0 0 0-10zm6.5-.25a1.25 1.25 0 0 0-2.5 0 1.25 1.25 0 0 0 2.5 0zM12 9a3 3 0 1 1 0 6 3 3 0 0 1 0-6z"
                fill="rgba(254,247,237,1)"
              />
            </svg>
          </a>
        </div>
      </nav>
    </div>
  );
}
