import React from "react";

export default function Hero({ hero }) {
  return (
    <>
      <header className="relative bg-black min-h-screen">
        <div className="absolute w-screen min-h-screen top-0 left-0 bottom-0 z-10 right-0 bg-gradient-to-b from-transparent to-black opacity-90"></div>
        {/* <div className="w-screen flex h-screen" style={{ background: `url(${hero.heroimg.url}) center center no-repeat`, backgroundSize: "cover", backgroundAttachment: "fixed" }}></div> */}
        {/* <div className="w-screen flex lg:hidden  h-screen" style={{ background: `url(${hero.heroimg.url}) center center no-repeat`, backgroundSize: "cover" }}></div> */}
        <div className="w-screen h-screen ">
          <div class="black"></div>
          <div class="e-with-fixed-bg">
            <div class="bg-wrap">
              <div class="bg" style={{ backgroundImage: `url('${hero.heroimg.url}')` }}></div>
            </div>
            <div class="e-container">
              <h1>This works well enought</h1>
            </div>
          </div>
          <div class="black"></div>
        </div>

        <div className="absolute z-20 top-0 w-screen h-screen flex flex-col items-center justify-center">
          <div className="container flex items-center justify-center mx-auto text-center">
            <h1 className="lg:text-7xl 2xl:text-8xl mt-24 text-4xl sm:text-6xl my font-light w-10/12 text-orange-50">{hero.herotext}</h1>
          </div>
          <svg className="h-10 absolute bottom-10 w-10" xmlns="http://www.w3.org/2000/svg" width="575.154" height="452.934" viewBox="0 0 575.154 452.934">
            <defs>
              <linearGradient id="linear-gradient" x1="0.5" x2="0.5" y2="0.791" gradientUnits="objectBoundingBox">
                <stop offset="0" stopColor="#279af1" />
                <stop offset="1" stopColor="#ff6722" />
              </linearGradient>
            </defs>
            <path
              id="Path_2"
              data-name="Path 2"
              d="M739.552,645.172s-4.978-7.622,37.63-5.963c59.15,2.3,178.153,13.115,236.622,3.288,45.435-7.636,52.943-39.678,57.972-50.961.722-1.621,1.7-8.107,1.234-10.9-2.2-13.631-25.41-33.324-62.465-45.275-63.065-20.343-163.883-27.858-200.7-30.7-9.166-.708-6.643-2.3-6.643-2.3s257.589-17.834,359.654,14.726c39.194,12.5,63.484,31.637,68.79,52.024,8.132,31.213-20.185,63.492-20.185,63.492s37.855-9.433,56.334-3.451,17.579,27.379,17.579,27.379,16.038.055,23.292,8.143,5.723,24.209,5.723,24.209-20.574-13.514-43.938-10.483-49.517,22.608-49.517,22.608,51.412,48.028-13.824,110.379c-47.557,45.454-158.694,104.79-215.055,130.431-20.3,9.233-32.065,8.593-32.065,8.593s-6.279-1.165,4.559-12.5c41.8-43.732,159.27-165.627,118.475-208.155-31.309-32.64-151.525-10.659-237.053,2.365-28.409,4.325-52.142,11.163-68.683,11.459-13.225.237-18.6-2.453-18.6-2.453s-12.965-2.928,27.362-16.663c26.334-8.968,73.377-18.522,104.033-27.239,15.443-4.391,21.709-6.7,21.709-6.7s26.431-7.992-18.014-17.408c-21.155-4.482-65.942-5.234-98.248-10.939-20.208-3.568-39.3-4.9-49.432-8.826A9.851,9.851,0,0,1,739.552,645.172Z"
              transform="translate(-739.373 -497.489)"
              fill="url(#linear-gradient)"
            />
          </svg>
        </div>
      </header>
    </>
  );
}
