import React from "react";

export default function Footer({ footer, contact }) {
  return (
    <section className="bg-white relative  py-20 w-screen">
      <div className="absolute z-0 top-0 left-0 bottom-0 right-0 bg-gradient-to-b from-white to-orange-600 opacity-30 "></div>
      <div className="relative z-20 w-screen">
        <div className="container px-10 mx-auto">
          <div className="mb-20 grid lg:grid-cols-5 gap-20">
            <div className="lg:col-span-3 col-span-1 flex lg:items-start lg:justify-start items-center justify-center">
              <img src={footer.logo.url} className="w-56" alt="" />
            </div>
            <div className="col-span-1 flex flex-col">
              <p className="nord">{contact.location}</p>
              <br />
              <br />
              <p className="nord">{contact.email}</p>
            </div>
            <div className="col-span-1 flex items-end  flex-col">
              <p className="nord">
                <a className="cursor-pointer" href={contact.facebook}>
                  Facebook
                </a>
              </p>
              <p className="nord">
                <a className="cursor-pointer" href={contact.instagram}>
                  Instagram
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
      <hr className="w-full border border-black " />
      <div className="w-full relative z-20 mt-8">
        <div className="container px-10 mx-auto grid grid-cols-2 pt-8">
          <p className="col-span-2 sm:col-span-1 sm:text-left text-center text-sm">&copy; 2022 Escape Hotel. All Rights Reserved</p>
          <p className="col-span-2 mt-2 sm:mt-0 sm:col-span-1 sm:text-right text-center text-sm">
            Developed by{" "}
            <a className="underline font-semibold" href="https://www.theoremstudio.com/">
              Theorem Studio
            </a>
          </p>
        </div>
      </div>
    </section>
  );
}
