import React from "react";

export default function FifthSection({ fifthSection }) {
  return (
    <section className="bg-black text-white w-screen  pt-10 pb-40 ">
      {/* <div className="container mx-auto grid lg:grid-cols-2 grid-cols-1 gap-16">
        <div className="flex flex-col items-start justify-start">
          <div className="flex items-center justify-start">
            <div className="mr-4 border border-orange-600 w-20 bg-orange-600"></div>
            <p className="nord font-thin lg:text-xl text-white">{fifthSection.subcript}</p>
          </div>
          <h2 className="lg:text-7xl sm:text-4xl text-3xl mt-8 lg:pr-40 sm:w-2/3 md:w-full my text-orange-600">{fifthSection.sectionHeading}</h2>
        </div>
        <div className="flex items-center justify-center">
          <p className="md:text-xl pop font-light text-orange-600 lg:w-3/4 text-justify mt-8">{fifthSection.sectionBody}</p>
        </div>
      </div> */}

      <div className="container px-10 mx-auto grid lg:grid-cols-2 grid-cols-1 gap-20">
        <div className="col-span-1">
          <div className="flex flex-col items-start justify-start">
            <div className="flex items-center justify-start">
              <div className="mr-4 border border-orange-600 w-20 bg-orange-600"></div>
              <p className="nord font-thin lg:text-xl text-white">{fifthSection.subcript}</p>
            </div>
            <h2 className="lg:text-7xl sm:text-4xl text-3xl mt-8 lg:pr-12 sm:w-2/3 md:w-full my text-orange-600">{fifthSection.sectionHeading}</h2>
          </div>
        </div>
        <p className="md:text-xl pop font-light text-orange-600  text-justify mt-8">{fifthSection.sectionBody}</p>
      </div>
      <div className="h-screen w-screen  mt-24 mx-auto object-cover overflow-hidden">
        <img src={fifthSection.photo.url} className="object-cover w-full h-full" alt="" />
      </div>
    </section>
  );
}
