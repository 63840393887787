import React, { useState } from "react";

export default function EighthSection({ eighthSection }) {
  const [showLightBox, setshowLightBox] = useState(false);
  const [currentImage, setCurrentImage] = useState(-1);
  const photos = [
    eighthSection.photo1.url,
    eighthSection.photo2.url,
    eighthSection.photo3.url,
    eighthSection.photo4.url,
    eighthSection.photo5.url,
    eighthSection.photo6.url,
    eighthSection.photo7.url,
    eighthSection.photo8.url,
    eighthSection.photo9.url,
  ];
  const setSho = () => {
    setshowLightBox(!showLightBox);
    if (showLightBox) {
      console.log("TRUE");
      document.body.style.overflow = "hidden";
    } else {
      console.log("FALSE");
      document.body.style.overflowY = "auto";
    }
  };
  const nextImage = () => {
    if (currentImage < photos.length - 1) {
      setCurrentImage(currentImage + 1);
    } else {
      setCurrentImage(0);
    }
  };
  const prevImage = () => {
    if (currentImage > 0) {
      setCurrentImage(currentImage - 1);
    } else {
      setCurrentImage(photos.length - 1);
    }
  };

  return (
    <section className="w-screen px-10 lg:pb-40 py-8  lg:min-h-screen">
      <div className="container mx-auto grid grid-cols-4 lg:gap-8 md:gap-6 gap-2">
        <div
          onClick={() => {
            setSho();
            setCurrentImage(0);
          }}
          className="square cursor-pointer bg-orange-50 col-span-1"
        >
          <img src={eighthSection.photo1.url} alt={eighthSection.photo1.alt} className="object-cover h-full w-full" />
        </div>
        <div
          onClick={() => {
            setshowLightBox(!showLightBox);
            setCurrentImage(1);
          }}
          className="rectangle cursor-pointer  bg-orange-50 col-span-2 "
        >
          <img src={eighthSection.photo2.url} alt={eighthSection.photo2.alt} className="object-cover h-full w-full" />
        </div>
        <div
          onClick={() => {
            setshowLightBox(!showLightBox);
            setCurrentImage(2);
          }}
          className="square cursor-pointer   bg-orange-50 "
        >
          <img src={eighthSection.photo3.url} alt={eighthSection.photo3.alt} className="object-cover h-full w-full" />
        </div>
        <div
          onClick={() => {
            setshowLightBox(!showLightBox);
            setCurrentImage(3);
          }}
          className="rectangle cursor-pointer   bg-orange-50 col-span-2 "
        >
          <img src={eighthSection.photo4.url} alt={eighthSection.photo4.alt} className="object-cover h-full w-full" />
        </div>
        <div
          onClick={() => {
            setshowLightBox(!showLightBox);
            setCurrentImage(4);
          }}
          className="square cursor-pointer   bg-orange-50 "
        >
          <img src={eighthSection.photo5.url} alt={eighthSection.photo5.alt} className="object-cover h-full w-full" />
        </div>
        <div
          onClick={() => {
            setshowLightBox(!showLightBox);
            setCurrentImage(5);
          }}
          className="square cursor-pointer   bg-orange-50 "
        >
          <img src={eighthSection.photo6.url} alt={eighthSection.photo6.alt} className="object-cover h-full w-full" />
        </div>
        <div
          onClick={() => {
            setshowLightBox(!showLightBox);
            setCurrentImage(6);
          }}
          className="square cursor-pointer   bg-orange-50"
        >
          <img src={eighthSection.photo7.url} alt={eighthSection.photo7.alt} className="object-cover h-full w-full" />
        </div>
        <div
          onClick={() => {
            setshowLightBox(!showLightBox);
            setCurrentImage(7);
          }}
          className="square cursor-pointer   bg-orange-50"
        >
          <img src={eighthSection.photo8.url} alt={eighthSection.photo8.alt} className="object-cover h-full w-full" />
        </div>
        <div
          onClick={() => {
            setshowLightBox(!showLightBox);
            setCurrentImage(8);
          }}
          className="rectangle cursor-pointer   bg-orange-50 col-span-2 "
        >
          <img src={eighthSection.photo9.url} alt={eighthSection.photo9.alt} className="object-cover h-full w-full" />
        </div>
      </div>
      {showLightBox ? (
        <>
          <div className=" bg-orange-50 z-40 fixed top-0 left-0 right-0 bottom-0">
            <img src={photos[currentImage]} className="md:w-screen object-cover w-full h-full  md:h-screen" />
            <button
              onClick={() => {
                setshowLightBox(!showLightBox);
                setCurrentImage(-1);
              }}
              className="absolute top-0 right-0 bg-orange-600 lg:w-20 lg:h-20 w-14 h-14 text-2xl hover:bg-orange-400 transition-all duration-200 text-orange-50"
            >
              &#x2715;
            </button>
            <div className="absolute lg:top-20 top-14 right-0 bottom-0 flex items-center justify-center">
              <button onClick={() => nextImage()} className="flex items-center justify-center bg-orange-50 lg:w-20 lg:h-20 w-14 h-14 text-2xl hover:bg-orange-400 transition-all duration-200 text-orange-50">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="48" height="48">
                  <path fill="none" d="M0 0h24v24H0z" />
                  <path d="M13.172 12l-4.95-4.95 1.414-1.414L16 12l-6.364 6.364-1.414-1.414z" fill="rgba(0,0,0,1)" />
                </svg>
              </button>
            </div>
            <div className="absolute lg:top-20 top-14 left-0 bottom-0 flex items-center justify-center">
              <button onClick={() => prevImage()} className=" flex items-center justify-center mt-10 left-0 bg-orange-50 lg:w-20 lg:h-20 w-14 h-14 text-2xl hover:bg-orange-400 transition-all duration-200 text-orange-50">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="48" height="48">
                  <path fill="none" d="M0 0h24v24H0z" />
                  <path d="M10.828 12l4.95 4.95-1.414 1.414L8 12l6.364-6.364 1.414 1.414z" />
                </svg>
              </button>
            </div>
          </div>
        </>
      ) : (
        <></>
      )}
    </section>
  );
}
