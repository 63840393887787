import React from "react";
import Parallax from "react-rellax";

export default function SixthSection({ sixthSection }) {
  return (
    <section className="bg-black text-orange-50 w-screen px-10 lg:-pb-20 pb-10 ">
      {/* <div className="container mx-auto grid lg:grid-cols-2 grid-cols-1 gap-16">
        <div className="flex flex-col items-start justify-start">
          <div className="flex items-center justify-start">
            <div className="mr-4 border border-orange-600 w-20 bg-orange-600"></div>
            <p className="nord font-thin lg:text-xl text-white">{sixthSection.subcript}</p>
          </div>
          <h2 className="lg:text-7xl sm:text-4xl text-3xl mt-8 lg:pr-40 sm:w-2/3 md:w-full my text-orange-600">{sixthSection.sectionHeading}</h2>
        </div>
        <div className="flex items-center justify-center">
          <p className="md:text-xl pop font-light text-orange-600 lg:w-3/4 text-justify mt-8">{sixthSection.sectionBody}</p>
        </div>
      </div> */}

      <div className="container  mx-auto grid lg:grid-cols-2  grid-cols-1 gap-20">
        <div className="col-span-1 ">
          <div className="flex flex-col items-start justify-start">
            <div className="flex items-center justify-start">
              <div className="mr-4 border border-orange-600 w-20 bg-orange-600"></div>
              <p className="nord font-thin lg:text-xl text-white">{sixthSection.subcript}</p>
            </div>
            <h2 className="lg:text-7xl sm:text-4xl text-3xl mt-8 lg:pr-12 sm:w-2/3 md:w-full my text-orange-600">{sixthSection.sectionHeading}</h2>
          </div>
        </div>
        <div className="col-span-1 mb-5 lg:mb-1">
          <p className="md:text-xl pop font-light text-orange-600 text-justify mt-8">{sixthSection.sectionBody}</p>
        </div>
      </div>
      <div className="container lg:hidden mx-auto grid grid-cols-1 gap-20 px-8">
        <div className="col-span-1">
          <div className="w-full relative rect">
            <img src={sixthSection.photo1.url} alt={sixthSection.photo1.alt} className="w-full h-full object-cover" />
            <div className="absolute top-0 left-0 right-0 bottom-0 bg-gradient-to-b from-transparent to-black opacity-70 flex items-center justify-center"></div>
            <div className="absolute top-0 left-0 right-0 bottom-0 bg-gradient-to-b flex items-center justify-center">
              <p className="text-4xl text-orange-50 my h-20">{sixthSection.photo1Title}</p>
            </div>
          </div>
        </div>
        <div className="col-span-1">
          <div className="w-full relative rect">
            <img src={sixthSection.photo2.url} alt={sixthSection.photo2.alt} className="w-full h-full object-cover" />
            <div className="absolute top-0 left-0 right-0 bottom-0 bg-gradient-to-b from-transparent to-black opacity-70"></div>{" "}
            <div className="absolute top-0 left-0 right-0 bottom-0 bg-gradient-to-b flex items-center justify-center">
              <p className="text-4xl text-orange-50 text-center my h-20">{sixthSection.photo2Title}</p>
            </div>
          </div>
        </div>
        <div className="col-span-1">
          <div className="w-full relative rect">
            <img src={sixthSection.photo3.url} alt={sixthSection.photo3.alt} className="w-full h-full object-cover" />
            <div className="absolute top-0 left-0 right-0 bottom-0 bg-gradient-to-b from-transparent to-black opacity-70"></div>
            <div className="absolute top-0 left-0 right-0 bottom-0 bg-gradient-to-b flex items-center justify-center">
              <p className="text-4xl text-orange-50 text-center my h-20">{sixthSection.photo3Title}</p>
            </div>
          </div>
        </div>
      </div>
      <div className="container hidden mt-96 pt-48 mx-auto lg:grid lg:grid-cols-3 grid-cols-1 gap-10">
        <Parallax speed={1.7}>
          <div className="col-span-1 ">
            <div className="w-full relative rect   ">
              <img src={sixthSection.photo1.url} alt={sixthSection.photo1.alt} className="w-full h-full object-cover" />
              <div className="absolute top-0 left-0 right-0 bottom-0 bg-gradient-to-b from-transparent to-black opacity-70 flex items-center justify-center"></div>
              <div className="absolute top-0 left-0 right-0 bottom-0 bg-gradient-to-b flex items-center justify-center">
                <p className="text-6xl text-orange-50 my h-20">{sixthSection.photo1Title}</p>
              </div>
            </div>
          </div>
        </Parallax>
        <Parallax speed={1}>
          <div className="col-span-1">
            <div className="w-full relative rect   ">
              <img src={sixthSection.photo2.url} alt={sixthSection.photo2.alt} className="w-full h-full object-cover" />
              <div className="absolute top-0 left-0 right-0 bottom-0 bg-gradient-to-b from-transparent to-black opacity-70"></div>{" "}
              <div className="absolute top-0 left-0 right-0 bottom-0 bg-gradient-to-b flex items-center justify-center">
                <p className="text-6xl text-orange-50 text-center my h-20">{sixthSection.photo2Title}</p>
              </div>
            </div>
          </div>
        </Parallax>
        <Parallax speed={1.4}>
          <div className="col-span-1">
            <div className="w-full relative rect  ">
              <img src={sixthSection.photo3.url} alt={sixthSection.photo3.alt} className="w-full h-full object-cover" />
              <div className="absolute top-0 left-0 right-0 bottom-0 bg-gradient-to-b from-transparent to-black opacity-70"></div>
              <div className="absolute top-0 left-0 right-0 bottom-0 bg-gradient-to-b flex items-center justify-center">
                <p className="text-6xl text-orange-50 text-center my h-20">{sixthSection.photo3Title}</p>
              </div>
            </div>
          </div>
        </Parallax>
      </div>
    </section>
  );
}
