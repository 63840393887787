import React, { useState } from "react";

export default function SeventhSection({ seventhSection }) {
  const [showMore, setshowMore] = useState(false);

  return (
    <div className="w-screen lg:-mt-36 mt-0 pt-20 pb-32 px-10 lg:pt-0 lg:mb-32 mb-14  bg-black">
      <div className="container mx-auto grid grid-cols-2 ">
        <div className="flex sticky h-96 top-10">
          <div className="flex  flex-col items-start justify-start">
            <div className="flex items-center justify-start">
              <div className="mr-4 border border-orange-600 w-20 bg-orange-600"></div>
              <p className="nord font-thin lg:text-xl text-orange-50">{seventhSection.subscript}</p>
            </div>
            <h2 className="lg:text-7xl sm:text-4xl text-3xl mt-8 lg:pr-40 sm:w-2/3 md:w-full my text-orange-600">{seventhSection.sectionHeading}</h2>
            <p className="md:text-xl pop font-light text-orange-600 lg:w-3/4 text-justify mt-8">{seventhSection.sectionBody}</p>
          </div>
        </div>
        <div className={`grid grid-cols-1 pt-20 ${showMore ? "h-6vh" : "h-3vh"}`}>
          <div className="h-screen mb-12 flex flex-col">
            <div className="w-full relative sqr">
              <img className="w-full h-full object-cover" src={seventhSection.photo1.url} alt={seventhSection.photo1.alt} />
              <div className="absolute top-0 left-0 right-0 bottom-0 bg-gradient-to-b from-transparent to-black"></div>
            </div>
            <h3 className="text-orange-600 text-5xl mt-8 my">{seventhSection.photo1Title}</h3>
            <p className="md:text-xl pop font-light text-orange-50 text-justify mt-8">{seventhSection.photo1Detail}</p>
          </div>
          <div className="h-screen mb-12 flex flex-col">
            <div className="w-full relative sqr">
              <img className="w-full h-full object-cover" src={seventhSection.photo2.url} alt={seventhSection.photo2.alt} />
              <div className="absolute top-0 left-0 right-0 bottom-0 bg-gradient-to-b from-transparent to-black"></div>
            </div>
            <h3 className="text-orange-600 text-5xl mt-8 my">{seventhSection.photo2Title}</h3>
            <p className="md:text-xl pop font-light text-orange-50 text-justify mt-8">{seventhSection.photo2Detail}</p>
          </div>
          <div className="min-h-screen mb-12 flex flex-col">
            <div className="w-full relative sqr">
              <img className="w-full h-full object-cover" src={seventhSection.photo3.url} alt={seventhSection.photo3.alt} />
              <div className="absolute top-0 left-0 right-0 bottom-0 bg-gradient-to-b from-transparent to-black"></div>
            </div>
            <h3 className="text-orange-600 text-5xl mt-8 my">{seventhSection.photo3Title}</h3>
            <p className="md:text-xl pop font-light text-orange-50 text-justify mt-8">{seventhSection.photo3Detail}</p>
            {showMore ? (
              <></>
            ) : (
              <>
                <button
                  onClick={() => {
                    setshowMore(true);
                  }}
                  className="bg-orange-600 w-fit px-4 text-lg mt-32 mb-20 p-2 rounded-md text-orange-50"
                >
                  Show More Attractions
                </button>
              </>
            )}
          </div>
          {showMore ? (
            <>
              <div className="h-screen mb-12 flex flex-col">
                <div className="w-full relative sqr">
                  <img className="w-full h-full object-cover" src={seventhSection.photo4.url} alt={seventhSection.photo4.alt} />
                  <div className="absolute top-0 left-0 right-0 bottom-0 bg-gradient-to-b from-transparent to-black"></div>
                </div>
                <h3 className="text-orange-600 text-5xl mt-8 my">{seventhSection.photo4Title}</h3>
                <p className="md:text-xl pop font-light text-orange-50 text-justify mt-8">{seventhSection.photo4Detail}</p>
              </div>
              <div className="h-screen mb-12 flex flex-col">
                <div className="w-full relative sqr">
                  <img className="w-full h-full object-cover" src={seventhSection.photo5.url} alt={seventhSection.photo5.alt} />
                  <div className="absolute top-0 left-0 right-0 bottom-0 bg-gradient-to-b from-transparent to-black"></div>
                </div>
                <h3 className="text-orange-600 text-5xl mt-8 my">{seventhSection.photo5Title}</h3>
                <p className="md:text-xl pop font-light text-orange-50 text-justify mt-8">{seventhSection.photo5Detail}</p>
              </div>
              <div className="h-screen mb-12 flex flex-col">
                <div className="w-full relative sqr">
                  <img className="w-full h-full object-cover" src={seventhSection.photo6.url} alt={seventhSection.photo6.alt} />
                  <div className="absolute top-0 left-0 right-0 bottom-0 bg-gradient-to-b from-transparent to-black"></div>
                </div>
                <h3 className="text-orange-600 text-5xl mt-8 my">{seventhSection.photo6Title}</h3>
                <p className="md:text-xl pop font-light text-orange-50 text-justify mt-8">{seventhSection.photo6Detail}</p>
                {!showMore ? (
                  <></>
                ) : (
                  <>
                    <button
                      onClick={() => {
                        setshowMore(false);
                      }}
                      className="bg-orange-600 w-fit px-4 text-lg mt-32 mb-20 p-2 rounded-md text-orange-50"
                    >
                      Show Less Attractions
                    </button>
                  </>
                )}
              </div>
            </>
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
}
