import React from "react";

export default function SecondSection({ secondSection }) {
  return (
    <>
      <section className="w-screen lg:py-40 py-10 sm:py-20 bg-orange-50">
        <div className="container flex items-center justify-center mx-auto">
          <p className="lg:text-5xl text-2xl sm:text-4xl leading-snug font-light pop text-center md:w-7/12 px-10 md:px-0">{secondSection.sectionParagraph}</p>
        </div>
      </section>
    </>
  );
}
