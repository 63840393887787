import React, { useState } from "react";

export default function MobileSeventhSection({ seventhSection }) {
  const [showMore, setshowMore] = useState(false);
  return (
    <div className="w-screen lg:-mt-36 mt-0 pt-20 lg:pt-0   bg-black pb-20">
      <div className="container mx-auto grid lg:grid-cols-2 grid-cols-1 gap-20">
        <div className="col-span-1 px-10">
          <div className="flex flex-col items-start justify-start">
            <div className="flex items-center justify-start">
              <div className="mr-4 border border-orange-600 w-20 bg-orange-600"></div>
              <p className="nord font-thin lg:text-xl text-white">{seventhSection.subscript}</p>
            </div>
            <h2 className="lg:text-7xl sm:text-4xl text-3xl mt-8 lg:pr-12 sm:w-2/3 md:w-full my text-orange-600">{seventhSection.sectionHeading}</h2>
            <p className="md:text-xl pop font-light text-orange-600 lg:w-3/4 text-justify mt-8">{seventhSection.sectionBody}</p>
          </div>
        </div>
      </div>
      <div className="container mx-auto mt-10 px-10 grid gap-8 sm:grid-cols-2 grid-cols-1">
        <div className="flex flex-col">
          <div className="w-full relative sqr">
            <img className="w-full h-full object-cover" src={seventhSection.photo1.url} alt={seventhSection.photo1.alt} />
            <div className="absolute top-0 left-0 right-0 bottom-0 bg-gradient-to-b from-transparent to-black"></div>
          </div>
          <h3 className="text-orange-600 text-3xl mt-8 my">{seventhSection.photo1Title}</h3>
          <p className="md:text-xl pop font-light text-orange-50 text-justify mt-2">{seventhSection.photo1Detail}</p>
        </div>
        <div className="flex flex-col">
          <div className="w-full relative sqr">
            <img className="w-full h-full object-cover" src={seventhSection.photo2.url} alt={seventhSection.photo2.alt} />
            <div className="absolute top-0 left-0 right-0 bottom-0 bg-gradient-to-b from-transparent to-black"></div>
          </div>
          <h3 className="text-orange-600 text-3xl mt-8 my">{seventhSection.photo2Title}</h3>
          <p className="md:text-xl pop font-light text-orange-50 text-justify mt-2">{seventhSection.photo2Detail}</p>
        </div>
        <div className="flex flex-col">
          <div className="w-full relative sqr">
            <img className="w-full h-full object-cover" src={seventhSection.photo3.url} alt={seventhSection.photo3.alt} />
            <div className="absolute top-0 left-0 right-0 bottom-0 bg-gradient-to-b from-transparent to-black"></div>
          </div>
          <h3 className="text-orange-600 text-3xl mt-8 my">{seventhSection.photo3Title}</h3>
          <p className="md:text-xl pop font-light text-orange-50 text-justify mt-2">{seventhSection.photo3Detail}</p>
        </div>
        {showMore ? (
          <>
            <div className="flex flex-col">
              <div className="w-full relative sqr">
                <img className="w-full h-full object-cover" src={seventhSection.photo4.url} alt={seventhSection.photo4.alt} />
                <div className="absolute top-0 left-0 right-0 bottom-0 bg-gradient-to-b from-transparent to-black"></div>
              </div>
              <h3 className="text-orange-600 text-3xl mt-8 my">{seventhSection.photo4Title}</h3>
              <p className="md:text-xl pop font-light text-orange-50 text-justify mt-2">{seventhSection.photo4Detail}</p>
            </div>
            <div className="flex flex-col">
              <div className="w-full relative sqr">
                <img className="w-full h-full object-cover" src={seventhSection.photo5.url} alt={seventhSection.photo5.alt} />
                <div className="absolute top-0 left-0 right-0 bottom-0 bg-gradient-to-b from-transparent to-black"></div>
              </div>
              <h3 className="text-orange-600 text-3xl mt-8 my">{seventhSection.photo5Title}</h3>
              <p className="md:text-xl pop font-light text-orange-50 text-justify mt-2">{seventhSection.photo5Detail}</p>
            </div>
            <div className="flex flex-col">
              <div className="w-full relative sqr">
                <img className="w-full h-full object-cover" src={seventhSection.photo6.url} alt={seventhSection.photo6.alt} />
                <div className="absolute top-0 left-0 right-0 bottom-0 bg-gradient-to-b from-transparent to-black"></div>
              </div>
              <h3 className="text-orange-600 text-3xl mt-8 my">{seventhSection.photo6Title}</h3>
              <p className="md:text-xl pop font-light text-orange-50 text-justify mt-2">{seventhSection.photo6Detail}</p>
            </div>
          </>
        ) : (
          ""
        )}
        <div className="flex  items-center justify-center">
          <button
            onClick={() => {
              setshowMore(!showMore);
            }}
            className="bg-orange-600 w-fit px-4 text-lg mt-32 mb-20 p-2 rounded-md text-orange-50"
          >
            {showMore ? "Show Less Attractions" : "Show More Attractions"}
          </button>
        </div>
      </div>
    </div>
  );
}
