import React from "react";

export default function NinthSection({ ninthSection, contact }) {
  return (
    <section className="w-screen bg-black flex items-center justify-center">
      {/* <div className="col-span-1 col-start-2 bg-orange-100 object-cover">
        <a href="https://www.google.com/maps/place/Escape+Hotel/@32.7709152,72.7135833,17z/data=!3m1!4b1!4m8!3m7!1s0x3920580f3ca36111:0x9efb939a7cc463c1!5m2!4m1!1i2!8m2!3d32.770918!4d72.7157623">
          <img src={ninthSection.map.url} className="w-full h-full object-cover" alt={ninthSection.map.alt} />
        </a>
      </div> */}
      <div className="container mx-auto grid lg:grid-cols-2 grid-cols-1 gap-20">
        <div className="col-span-2 lg:col-span-1 pl-10 pb-10">
          <div className="flex flex-col items-start justify-start">
            <div className="flex items-center justify-start">
              <div className="mr-4 border border-orange-600 w-20 bg-orange-600"></div>
              <p className="nord font-thin lg:text-xl text-white">{ninthSection.subscript}</p>
            </div>
            <h2 className="lg:text-7xl sm:text-4xl text-3xl mt-8 lg:pr-40 sm:w-2/3 md:w-full my text-orange-600">{ninthSection.sectionHeading}</h2>
            <p className="lg:text-2xl pop font-light text-orange-600 mt-8 w-2/3">{contact.location}</p>
            <a
              className="lg:text-2xl underline pop font-light text-orange-600 mt-8 w-2/3"
              href="https://www.google.com/maps/place/Escape+Hotel/@32.7709152,72.7135833,17z/data=!3m1!4b1!4m8!3m7!1s0x3920580f3ca36111:0x9efb939a7cc463c1!5m2!4m1!1i2!8m2!3d32.770918!4d72.7157623"
            >
              View On Map
            </a>
            <p className="lg:text-2xl pop font-light text-orange-600 mt-8">{contact.phone}</p>
            <p className="lg:text-2xl pop font-light text-orange-600 mt-8">{contact.email}</p>
          </div>
        </div>
        <div className="col-span-2 lg:col-span-1">
          <a href="https://www.google.com/maps/place/Escape+Hotel/@32.7709152,72.7135833,17z/data=!3m1!4b1!4m8!3m7!1s0x3920580f3ca36111:0x9efb939a7cc463c1!5m2!4m1!1i2!8m2!3d32.770918!4d72.7157623">
            <img src={ninthSection.map.url} className="w-full h-full object-cover" alt={ninthSection.map.alt} />
          </a>
        </div>
      </div>
      {/* <div
        className="absolute lg:top-0 lg:left-0 lg:right-0 lg:bottom-0 bg-orange-50 lg:bg-transparent flex p-10 lg:p-0
      "
      >
        <div className="container mx-auto h-full grid grid-cols-2">
          <div className="flex flex-col col-span-2 lg:col-span-1 items-start justify-start">
            <div className="flex items-center justify-start">
              <div className="mr-4 border border-orange-600 w-20 bg-orange-600"></div>
              <p className="nord font-thin lg:text-xl lg:text-orange-50 text-black">{ninthSection.subscript}</p>
            </div>
            <h2 className="lg:text-7xl sm:text-4xl text-3xl mt-8 lg:pr-40 sm:w-2/3 md:w-full my text-orange-600">{ninthSection.sectionHeading}</h2>
            <p className="lg:text-2xl pop font-light text-orange-600 mt-8 w-2/3">Islamabad Motorway Intersection - 7A Kallar Kahar, Pakistan</p>
            <a
              className="lg:text-2xl underline pop font-light text-orange-600 mt-8 w-2/3"
              href="
              https://www.google.com/maps/place/Escape+Hotel/@32.7709152,72.7135833,17z/data=!3m1!4b1!4m8!3m7!1s0x3920580f3ca36111:0x9efb939a7cc463c1!5m2!4m1!1i2!8m2!3d32.770918!4d72.7157623
              "
            >
              View On Map
            </a>
            <p className="lg:text-2xl pop font-light text-orange-600 mt-8">{ninthSection.phone}</p>
            <p className="lg:text-2xl pop font-light text-orange-600 mt-8">{ninthSection.email}</p>
          </div>
        </div>
      </div> */}
    </section>
  );
}
