import React from "react";
import Parallax from "react-rellax";

export default function FourthSection({ fourthSection }) {
  return (
    <section className="bg-black px-10 text-white w-screen min-h-screen lg:py-72 lg:pt-40 py-10">
      <div className="container mx-auto grid lg:grid-cols-2 grid-cols-1 gap-20">
        <div className="col-span-1">
          <div className="flex flex-col items-start justify-start">
            <div className="flex items-center justify-start">
              <div className="mr-4 border border-orange-600 w-20 bg-orange-600"></div>
              <p className="nord font-thin lg:text-xl text-white">{fourthSection.subcript}</p>
            </div>
            <h2 className="lg:text-7xl sm:text-4xl text-3xl mt-8 lg:pr-12 sm:w-2/3 md:w-full my text-orange-600">{fourthSection.sectionHeading}</h2>
          </div>
        </div>
        <p className="md:text-xl pop font-light text-orange-600  text-justify mt-8">{fourthSection.sectionBody}</p>
      </div>
      <div className="container mx-auto mt-8 lg:hidden grid grid-cols-2 gap-3 px-8">
        <div className="col-span-1">
          <div className="w-full h-48  ">
            <img src={fourthSection.photo1.url} alt={fourthSection.photo1.alt} className="w-full h-full object-cover" />
          </div>
        </div>
        <div className="col-span-1">
          <div className="w-full h-48  ">
            <img src={fourthSection.photo2.url} alt={fourthSection.photo2.alt} className="w-full h-full object-cover" />
          </div>
        </div>
        <div className="col-span-1">
          <div className="w-full h-48  ">
            <img src={fourthSection.photo3.url} alt={fourthSection.photo3.alt} className="w-full h-full object-cover" />
          </div>
        </div>
        <div className="col-span-1">
          <div className="w-full h-48  ">
            <img src={fourthSection.photo4.url} alt={fourthSection.photo4.alt} className="w-full h-full object-cover" />
          </div>
        </div>
      </div>
      <div className="container hidden relative mt-72 mx-auto lg:grid lg:grid-cols-4 grid-cols-1 lg:gap-4">
        <Parallax as="div" className="col-span-1" speed={0.4}>
          {/* <div className="col-span-1 border-2 border-purple-200"> */}
          <div className="w-full h-96  ">
            <img src={fourthSection.photo1.url} alt={fourthSection.photo1.alt} className="w-full h-full object-cover" />
          </div>
          {/* </div> */}
        </Parallax>
        <Parallax speed={-0.45}>
          <div className="col-span-1">
            <div className="w-full h-96  ">
              <img src={fourthSection.photo2.url} alt={fourthSection.photo2.alt} className="w-full h-full object-cover" />
            </div>
          </div>
        </Parallax>
        <Parallax speed={1}>
          <div className="col-span-1">
            <div className="w-full h-96  ">
              <img src={fourthSection.photo3.url} alt={fourthSection.photo3.alt} className="w-full h-full object-cover" />
            </div>
          </div>
        </Parallax>
        <Parallax speed={-0.45}>
          <div className="col-span-1">
            <div className="w-full h-96  ">
              <img src={fourthSection.photo4.url} alt={fourthSection.photo4.alt} className="w-full h-full object-cover" />
            </div>
          </div>
        </Parallax>
      </div>
    </section>
  );
}
