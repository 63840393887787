import React from "react";

export default function ThirdSection({ thirdSection }) {
  return (
    <>
      <div className="w-screen bg-black px-10 pb-14">
        <div className="container lg:pt-40 pt-20 lg:pb-32 pb-20 mx-auto grid gap-10 lg:gap-20 grid-cols-1 lg:grid-cols-2">
          <div className="col-span-1">
            <div className="flex flex-col items-start justify-start">
              <div className="flex items-center justify-start">
                <div className="mr-4 border border-orange-600 w-20 bg-orange-600"></div>
                <p className="nord font-thin lg:text-xl text-white">{thirdSection.subscript}</p>
              </div>
              <h2 className="lg:text-7xl sm:text-4xl text-3xl mt-8 lg:pr-12 sm:w-2/3 md:w-full my text-orange-600">{thirdSection.sectionHeading}</h2>
              <p className="md:text-xl pop font-light text-orange-600 lg:w-3/4 text-justify mt-8">{thirdSection.sectionBody}</p>
            </div>
          </div>
          <div className="col-span-1 flex lg:hidden flex-col lg:flex-row px-10">
            <img className="object-cover w-full lg:mr-4 lg:w-1/2 h-full" src={thirdSection.upperPhoto.url} alt={thirdSection.upperPhoto.alt} />
            <img className="object-cover mt-8 lg:mt-0 lg:ml-4  w-full lg:w-1/2 h-full" src={thirdSection.lowerPhoto.url} alt={thirdSection.lowerPhoto.alt} />
          </div>
          <div className="col-span-1 lg:grid gap-4 grid-cols-2 hidden relative ">
            <div className=" col-span-1 flex items-start justify-start w-full">
              <img src={thirdSection.upperPhoto.url} alt={thirdSection.upperPhoto.alt} className="object-cover w-full h-4/5" />
            </div>
            <div className=" col-span-1 flex items-end justify-end w-full">
              <img src={thirdSection.lowerPhoto.url} alt={thirdSection.lowerPhoto.alt} className="object-cover w-full h-4/5" />
            </div>
            {/* <div className="h-3/5 w-1/3 absolute top-80 right-24 overflow-hidden object-cover bg-black">
              <img src={thirdSection.lowerPhoto.url} alt={thirdSection.lowerPhoto.alt} className="w-full h-full" />
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
}
